import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import { Affix, Tabs, Tag, message } from 'antd';
import Presence from '~/components/Presence';

import IpAddressForm from './IpAddressForm';
import { UpdateSecurityMutation } from './mutations';
import { useFormBase } from '../form/FormBase';

const { TabPane } = Tabs;

const SecurityView = (props) => {
  const { viewer, relay, match } = props;

  const previewIps = get(viewer, 'securities.ip_addresses', '[]');
  const internalIps = get(viewer, 'securities.internal_ip_addresses', '[]');

  const { shouldDisableBtn, handleDisableBtn } = useFormBase();
  const [activeKey, setActiveKey] = useState("preview");

  const handleSubmit = (values) => {
    if (values.ip_addresses) {
      const ips = values.ip_addresses.map(({ ip, description }) => ({
        ip: ip.trim(),
        description: description.trim(),
      }));

      values.ip_addresses = JSON.stringify(ips);
    }

    if (values.internal_ip_addresses) {
      const ips = values.internal_ip_addresses.map(({ ip, description }) => ({
        ip: ip.trim(),
        description: description.trim(),
      }));

      values.internal_ip_addresses = JSON.stringify(ips);
    }

    UpdateSecurityMutation.commit({
      environment: relay.environment,
      variables: { input: { securities: JSON.stringify(values) } },
      viewer,
      safeGuard: "update",
      onError: (errors) => {
        if (errors[0] && errors[0].message) {
          const msg = errors[0].message;
          message.error(msg);
        }
      }
    });
  };

  return (
    <div>
      <div>
        IP Address Input Rules:
        <ul>
          <li>
            <b>IPv4 addresses are accepted.</b> <Tag>(e.g., 192.168.1.100)</Tag>
          </li>
          {activeKey === "preview" ? (
            <li>
              IPv6 addresses are supported. <Tag>(e.g., 2001:db8::1)</Tag>
            </li>
          ) : (
            <li>
              IPv6 addresses are <b>NOT</b> supported. <Tag>(e.g., 2001:db8::1)</Tag>
            </li>
          )}
          <li>
            CIDR notation is not supported. <Tag>(e.g., 192.168.1.0/24)</Tag>
          </li>
        </ul>
      </div>

      <Affix>
        <Presence match={match} disableButton={handleDisableBtn} />
      </Affix>

      <Tabs activeKey={activeKey} onChange={setActiveKey}>
        <TabPane key="preview" tab="Preview IP Addresses">
          <IpAddressForm
            fieldName="ip_addresses"
            label="Preview IP Addresses"
            ips={previewIps}
            handleSubmit={handleSubmit}
            shouldDisableBtn={shouldDisableBtn}
            allowIPv6
          />
        </TabPane>
        <TabPane key="internal" tab="Internal IP Addresses">
          <IpAddressForm
            fieldName="internal_ip_addresses"
            label="Internal IP Addresses"
            ips={internalIps}
            handleSubmit={handleSubmit}
            shouldDisableBtn={shouldDisableBtn}
          />
        </TabPane>
      </Tabs>
    </div>
  )
};

SecurityView.propTypes = {
  viewer: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  relay: PropTypes.shape({
    environment: PropTypes.shape({}).isRequired,
  }).isRequired,
  match: PropTypes.shape({}).isRequired,
};

export default createFragmentContainer(SecurityView, {
  viewer: graphql`
    fragment SecurityView_viewer on Admin {
      id
      securities
    }
  `,
});
