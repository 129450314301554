import React from 'react';
import PropTypes from 'prop-types';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { get } from 'lodash';
import { Button, Col, Divider, Form, Input, InputNumber, Row, Select, Upload } from 'antd';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { fileValidator, imageValidator } from '~/components/form';

const { Item: FormItem } = Form;
const { Option } = Select;

class DisplayCategory extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      allCategories: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    category: PropTypes.shape({
      id: PropTypes.string,
      displayCategories: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  }

  render() {
    const { category, viewer } = this.props;

    if (Object.keys(category).length === 0) {
      return null;
    }

    const allCategories = get(viewer, 'allCategories.edges', []);
    const displayCategories = get(category, 'displayCategories', []);

    const initialValue = displayCategories.length ? displayCategories.slice(0).map(node => {
      const id = get(node, "id", null);
      const categoryId = get(node, "categoryId", null);
      const nestedCategoryId = get(node, "nestedCategoryId", null);
      const position = get(node, "position", 0.0);
      const imageUrl = get(node, "imageUrl", null);

      return (
        {
          id,
          nestedCategoryId,
          categoryId,
          position,
          imageUrl,
        }
      )
    }) : [];

    return (
      <Form.List name="displayCategories" initialValue={initialValue}>
        {(fields, { add, remove }) => (
          <>
            {fields.sort((a, b) => a.position - b.position).map(({ key, name }) => (
              <div key={key}>
                <FormItem
                  name={[name, "id"]}
                  hidden
                >
                  <Input />
                </FormItem>

                <FormItem
                  name={[name, "categoryId"]}
                  rules={[{ required: true, message: 'required' }]}
                  hidden
                >
                  <Input />
                </FormItem>

                <Row>
                  <Col xs={24} sm={8}>
                    <FormItem
                      label={
                        <div style={{ display: "inline-block" }}>
                          {fields.length > 0 ? (
                            <MinusCircleOutlined
                              style={{ cursor: 'pointer', margin: "0 10px" }}
                              disabled={fields.length === 1}
                              onClick={() => remove(name)}
                            />
                          ) : null}
                        </div>
                      }
                    >
                      <FormItem
                        name={[name, "nestedCategoryId"]}
                        rules={[{ required: true, message: 'required' }]}
                      >
                        <Select
                          placeholder="Category"
                          optionFilterProp="children"
                          optionLabelProp="children"
                          showSearch
                        >
                          {
                            allCategories.map((catEdge) => {
                              const o = catEdge.node;
                              return <Option key={o.id} value={o.id}>{o.name}</Option>;
                            })
                          }
                        </Select>
                      </FormItem>
                    </FormItem>
                  </Col>

                  <Col>
                    <FormItem
                      name={[name, 'position']}
                      label="Position"
                      rules={[{ required: true, message: 'required' }]}
                    >
                      <InputNumber placeholder="Position" style={{width: '60px'}} />
                    </FormItem>
                  </Col>

                  <Col xs={24} sm={12}>
                    <FormItem
                      label="Thumbnail"
                    >
                      <Row>
                        <Col>
                          <FormItem
                            name={[name, "image"]}
                            valuePropName="fileList"
                            extra="Thumbnail for this category"
                            getValueFromEvent={(e) => {
                              if (Array.isArray(e)) {
                                return e;
                              }
                              return e && e.fileList;
                            }}
                            rules={[
                              { required: true, message: 'File cannot be over 1MB', validator: fileValidator.bind(this, 1) },
                              { required: true, message: 'Image size should be either 240x240', validator: imageValidator.bind(this, [[240, 240]]) },
                              ({ getFieldValue }) => ({
                                validator() {
                                  // this record has an existing image url
                                  const imageUrl = getFieldValue(["displayCategories", name, "imageUrl"]);
                                  // this record has an image to bee uploaded
                                  const image = getFieldValue(["displayCategories", name, "image"]);

                                  if (imageUrl || image) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(new Error('required'));
                                },
                              }),
                            ]}
                          >
                            <Upload
                              accept="image/gif,image/png,image/jpeg"
                              beforeUpload={() => false}
                              listType="picture"
                            >
                              <Button>
                                <UploadOutlined /> Upload
                              </Button>
                            </Upload>
                          </FormItem>
                        </Col>
                        <Col>
                          <FormItem noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                              const imageUrl = getFieldValue(["displayCategories", name, "imageUrl"]);
                              return imageUrl ? (
                                <img alt="" src={imageUrl} width="120" />
                              ) : null
                            }}
                          </FormItem>
                        </Col>
                      </Row>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Divider />
                </Row>
              </div>
            ))}
            <Button onClick={() => add({ categoryId: category.id, position: 0.0 })}>
              <PlusOutlined />
            </Button>
          </>
        )}
      </Form.List>
    );
  }
}

export default createFragmentContainer(DisplayCategory, {
  viewer: graphql`
    fragment DisplayCategory_viewer on Admin {
      allCategories: categories(first: 9999, orderBy: {field: "name", direction: "asc"}) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `,
});

