/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CategoryForm_viewer$ref = any;
type CategoryHistory_category$ref = any;
type WebsiteRef_category$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CategoryView_viewer$ref: FragmentReference;
declare export opaque type CategoryView_viewer$fragmentType: CategoryView_viewer$ref;
export type CategoryView_viewer = {|
  +categories: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +urlSlug: ?string,
        +description: ?string,
        +description2: ?string,
        +includeInMenu: ?boolean,
        +includeInSearch: ?boolean,
        +includeInSitemap: ?boolean,
        +includeInFilter: ?boolean,
        +internalPriceMatch: ?boolean,
        +featured: ?boolean,
        +position: ?number,
        +aliases: ?$ReadOnlyArray<?string>,
        +displayMode: ?string,
        +displayCategories: ?$ReadOnlyArray<?{|
          +id: string,
          +categoryId: ?string,
          +nestedCategoryId: ?string,
          +position: ?number,
          +imageUrl: ?string,
        |}>,
        +brands: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +imageUrl: ?string,
            |}
          |}>
        |},
        +featuredBrands: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +brand: ?{|
                +id: string,
                +name: ?string,
                +imageUrl: ?string,
              |},
              +subcategory: ?{|
                +id: string,
                +name: ?string,
              |},
            |}
          |}>
        |},
        +scheduleAssignments: ?$ReadOnlyArray<?{|
          +id: string,
          +datetime: ?string,
          +products: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +name: ?string,
                +mainImage: ?{|
                  +id: string,
                  +url: ?string,
                |},
              |}
            |}>
          |},
        |}>,
        +scheduleRemovals: ?$ReadOnlyArray<?{|
          +id: string,
          +datetime: ?string,
          +products: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +name: ?string,
                +mainImage: ?{|
                  +id: string,
                  +url: ?string,
                |},
              |}
            |}>
          |},
        |}>,
        +status: ?boolean,
        +enableStartDate: ?string,
        +enableEndDate: ?string,
        +previewDescription: ?string,
        +metaTitle: ?string,
        +metaDescription: ?string,
        +wideImage: ?boolean,
        +jsonLd: ?string,
        +labels: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
            |}
          |}>
        |},
        +$fragmentRefs: CategoryHistory_category$ref & WebsiteRef_category$ref,
      |}
    |}>
  |},
  +$fragmentRefs: CategoryForm_viewer$ref,
  +$refType: CategoryView_viewer$ref,
|};
export type CategoryView_viewer$data = CategoryView_viewer;
export type CategoryView_viewer$key = {
  +$data?: CategoryView_viewer$data,
  +$fragmentRefs: CategoryView_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 9999
  }
],
v5 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v3/*: any*/)
],
v6 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v7 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "datetime",
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v4/*: any*/),
    "concreteType": "ProductConnection",
    "kind": "LinkedField",
    "name": "products",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductImage",
                "kind": "LinkedField",
                "name": "mainImage",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "products(first:9999)"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "ids"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CategoryView_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "ids"
        }
      ],
      "concreteType": "CategoryConnection",
      "kind": "LinkedField",
      "name": "categories",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CategoryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Category",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "urlSlug",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description2",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "includeInMenu",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "includeInSearch",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "includeInSitemap",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "includeInFilter",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "internalPriceMatch",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "featured",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "aliases",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayMode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DisplayCategory",
                  "kind": "LinkedField",
                  "name": "displayCategories",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "categoryId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "nestedCategoryId",
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v4/*: any*/),
                  "concreteType": "BrandConnection",
                  "kind": "LinkedField",
                  "name": "brands",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BrandEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Brand",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": (v5/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "brands(first:9999)"
                },
                {
                  "alias": null,
                  "args": (v4/*: any*/),
                  "concreteType": "FeaturedBrandConnection",
                  "kind": "LinkedField",
                  "name": "featuredBrands",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "FeaturedBrandEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "FeaturedBrand",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Brand",
                              "kind": "LinkedField",
                              "name": "brand",
                              "plural": false,
                              "selections": (v5/*: any*/),
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Subcategory",
                              "kind": "LinkedField",
                              "name": "subcategory",
                              "plural": false,
                              "selections": (v6/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "featuredBrands(first:9999)"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CategoryScheduleAssignments",
                  "kind": "LinkedField",
                  "name": "scheduleAssignments",
                  "plural": true,
                  "selections": (v7/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CategoryScheduleRemovals",
                  "kind": "LinkedField",
                  "name": "scheduleRemovals",
                  "plural": true,
                  "selections": (v7/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "enableStartDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "enableEndDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "previewDescription",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "metaTitle",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "metaDescription",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "wideImage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "jsonLd",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 999
                    }
                  ],
                  "concreteType": "LabelConnection",
                  "kind": "LinkedField",
                  "name": "labels",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "LabelEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Label",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": (v6/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "labels(first:999)"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CategoryHistory_category"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "WebsiteRef_category"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CategoryForm_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '61e798de1e73707dd9a10032e91dd4b3';

module.exports = node;
