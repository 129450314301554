import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation UpdateBrandMutation($input: UpdateBrandInput!) {
    updateBrand(input:$input) {
      brand {
        name
        imageUrl
        urlSlug
        status
        metaTitle
        metaDescription
        description
        description2
        expressShipping
        privateLabel
        searchScoreMultiplier
        popular
        popularScore
        ...BrandHistory_brand
        ...WebsiteRef_brand
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
