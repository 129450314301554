import { graphql } from 'react-relay';
import { buildMutation } from '../../../../mutation';

const mutation = graphql`
  mutation UpdateCommonPromoMutation($input: UpdateCommonPromoInput!) {
    updateCommonPromo(input:$input) {
      commonPromo {
        name
        startDate
        endDate
        onProducts(first: 99999) {
          edges {
            node {
              id
              name
              mainImage {
                id
                url
              }
            }
          }
        }
        type
        brandIds
        freebieId
        excludeProducts(first: 99999) {
          edges {
            node {
              id
              name
              status
              mainImage {
                id
                url
              }
            }
          }
        }
        images (first: 999) {
          edges {
            node {
              id
              imageUrl
            }
          }
        }
        wideBannerBig
        wideBannerSmall
        status
        ...CommonPromoHistory_commonPromo
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
