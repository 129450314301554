/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AddressForm_viewer$ref = any;
type SalesOrderHistory_salesOrder$ref = any;
type SalesOrderHistory_viewer$ref = any;
type SalesOrderVerification_order$ref = any;
type SalesOrderVerification_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SalesOrderView_viewer$ref: FragmentReference;
declare export opaque type SalesOrderView_viewer$fragmentType: SalesOrderView_viewer$ref;
export type SalesOrderView_viewer = {|
  +salesOrders: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +email: ?string,
        +subtotal: ?number,
        +grandTotal: ?number,
        +shippingTotal: ?number,
        +shippingDescription: ?string,
        +shippingMethod: ?string,
        +paymentMethod: ?string,
        +discount: ?number,
        +discountDescription: ?string,
        +surcharge: ?number,
        +surcharges: ?$ReadOnlyArray<?{|
          +name: ?string,
          +amount: ?number,
        |}>,
        +verified: ?number,
        +status: ?number,
        +shipments: ?any,
        +shipping: ?any,
        +verification: ?{|
          +firstAmount: ?number,
          +secondAmount: ?number,
          +verifiedAt: ?string,
          +verifiedBy: ?string,
          +completedBy: ?string,
          +last4: ?string,
          +voidedBy: ?string,
          +cancelledBy: ?string,
          +attempts: ?number,
        |},
        +remoteIp: ?string,
        +insertedAt: ?string,
        +customer: ?{|
          +id: string,
          +firstname: ?string,
          +lastname: ?string,
        |},
        +discounts: ?$ReadOnlyArray<?{|
          +id: ?string,
          +name: ?string,
          +code: ?string,
          +amount: ?number,
        |}>,
        +addresses: ?{|
          +edges: $ReadOnlyArray<{|
            +node: {|
              +id: string,
              +addressType: ?string,
              +firstname: ?string,
              +lastname: ?string,
              +street: ?string,
              +city: ?string,
              +postcode: ?string,
              +region: ?string,
              +country: ?{|
                +name: ?string
              |},
              +telephone: ?string,
              +company: ?string,
              +fax: ?string,
              +editable: ?boolean,
              +distanceFromStore: ?number,
              +store: ?{|
                +id: string,
                +name: ?string,
              |},
            |}
          |}>
        |},
        +lines: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +canExport: ?boolean,
              +quantity: ?number,
              +unitPrice: ?number,
              +unitDiscount: ?number,
              +unitSurcharge: ?number,
              +rowTotal: ?number,
              +priceMatch: ?{|
                +id: string
              |},
              +product: ?{|
                +id: string,
                +adenCode: ?string,
              |},
              +shipments: ?$ReadOnlyArray<?{|
                +tracking: ?any,
                +location: ?string,
                +status: ?string,
                +timestamp: ?string,
              |}>,
              +priorityShipping: ?{|
                +getByDate: ?string,
                +shippingMethod: ?string,
              |},
              +children: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +name: ?string,
                    +canExport: ?boolean,
                    +product: ?{|
                      +id: string,
                      +adenCode: ?string,
                    |},
                    +quantity: ?number,
                    +unitPrice: ?number,
                    +unitDiscount: ?number,
                    +unitSurcharge: ?number,
                    +rowTotal: ?number,
                    +shipments: ?$ReadOnlyArray<?{|
                      +tracking: ?any,
                      +location: ?string,
                      +status: ?string,
                      +timestamp: ?string,
                    |}>,
                    +priorityShipping: ?{|
                      +getByDate: ?string,
                      +shippingMethod: ?string,
                    |},
                  |}
                |}>
              |},
            |}
          |}>
        |},
        +$fragmentRefs: SalesOrderVerification_order$ref & SalesOrderHistory_salesOrder$ref,
      |}
    |}>
  |},
  +$fragmentRefs: SalesOrderVerification_viewer$ref & AddressForm_viewer$ref & SalesOrderHistory_viewer$ref,
  +$refType: SalesOrderView_viewer$ref,
|};
export type SalesOrderView_viewer$data = SalesOrderView_viewer;
export type SalesOrderView_viewer$key = {
  +$data?: SalesOrderView_viewer$data,
  +$fragmentRefs: SalesOrderView_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shippingMethod",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 99
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canExport",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitPrice",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitDiscount",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitSurcharge",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowTotal",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "product",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adenCode",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "SalesShipmentLine",
  "kind": "LinkedField",
  "name": "shipments",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tracking",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timestamp",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "PriorityShipping",
  "kind": "LinkedField",
  "name": "priorityShipping",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "getByDate",
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "ids"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SalesOrderView_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "ids"
        }
      ],
      "concreteType": "SalesOrderConnection",
      "kind": "LinkedField",
      "name": "salesOrders",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SalesOrderEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SalesOrder",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subtotal",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "grandTotal",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "shippingTotal",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "shippingDescription",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paymentMethod",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "discount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "discountDescription",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "surcharge",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderSurcharge",
                  "kind": "LinkedField",
                  "name": "surcharges",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "verified",
                  "storageKey": null
                },
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "shipments",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "shipping",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesOrderVerification",
                  "kind": "LinkedField",
                  "name": "verification",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstAmount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "secondAmount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "verifiedAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "verifiedBy",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "completedBy",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "last4",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "voidedBy",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cancelledBy",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "attempts",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "remoteIp",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "insertedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Customer",
                  "kind": "LinkedField",
                  "name": "customer",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Discount",
                  "kind": "LinkedField",
                  "name": "discounts",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "code",
                      "storageKey": null
                    },
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 2
                    }
                  ],
                  "concreteType": "SalesAddressConnection",
                  "kind": "LinkedField",
                  "name": "addresses",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesAddressEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SalesAddress",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "addressType",
                              "storageKey": null
                            },
                            (v5/*: any*/),
                            (v6/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "street",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "city",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "postcode",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "region",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Country",
                              "kind": "LinkedField",
                              "name": "country",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/)
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "telephone",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "company",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "fax",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "editable",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "distanceFromStore",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Store",
                              "kind": "LinkedField",
                              "name": "store",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v1/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "addresses(first:2)"
                },
                {
                  "alias": null,
                  "args": (v7/*: any*/),
                  "concreteType": "SalesOrderLineConnection",
                  "kind": "LinkedField",
                  "name": "lines",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesOrderLineEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SalesOrderLine",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            (v8/*: any*/),
                            (v9/*: any*/),
                            (v10/*: any*/),
                            (v11/*: any*/),
                            (v12/*: any*/),
                            (v13/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PriceMatch",
                              "kind": "LinkedField",
                              "name": "priceMatch",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/)
                              ],
                              "storageKey": null
                            },
                            (v14/*: any*/),
                            (v15/*: any*/),
                            (v16/*: any*/),
                            {
                              "alias": null,
                              "args": (v7/*: any*/),
                              "concreteType": "SalesOrderLineConnection",
                              "kind": "LinkedField",
                              "name": "children",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "SalesOrderLineEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "SalesOrderLine",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": [
                                        (v0/*: any*/),
                                        (v1/*: any*/),
                                        (v8/*: any*/),
                                        (v14/*: any*/),
                                        (v9/*: any*/),
                                        (v10/*: any*/),
                                        (v11/*: any*/),
                                        (v12/*: any*/),
                                        (v13/*: any*/),
                                        (v15/*: any*/),
                                        (v16/*: any*/)
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": "children(first:99)"
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "lines(first:99)"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SalesOrderVerification_order"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SalesOrderHistory_salesOrder"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SalesOrderVerification_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddressForm_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SalesOrderHistory_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '7bb011b2842c82be7c33d8753b57c620';

module.exports = node;
