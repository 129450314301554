import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Checkbox } from 'antd';

import {
  CartesianGrid,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const { Group } = Checkbox;

const SCALE_FACTOR = 300;

class SalesTrend extends React.Component {
  static propTypes = {
    salesTrend: PropTypes.arrayOf(PropTypes.array).isRequired,
  }

  constructor(props) {
    super(props);

    this.defaultValue = ['Order Count', 'Last Year Grand Total', 'Last Year Order Count'];

    this.options = [
      { label: 'Order Count', value: 'Order Count' },
      { label: 'Last Year Grand Total', value: 'Last Year Grand Total' },
      { label: 'Last Year Order Count', value: 'Last Year Order Count' },
    ];

    this.state = {
      selectedOption: this.defaultValue,
    };
  }

  onCheckboxChange = (selectedOption) => {
    this.setState({ selectedOption });
  }

  renderLine = (data, name, props = {}) => {
    const { selectedOption } = this.state;

    if (selectedOption.includes(name)) {
      return <Line dataKey="value" data={data} name={name} key={name} strokeDasharray="3 4 5 2" {...props} />;
    }
    return null;
  }

  render() {
    const salesTrend = get(this.props, "SalesTrend", []);

    const orderCounts = salesTrend.map(t => ({
      category: t[0],
      value: t[1] * SCALE_FACTOR,
    }))

    const grandTotals = salesTrend.map(t => ({
      category: t[0],
      value: t[2]
    }))

    const prevOrderCounts = salesTrend.map(t => ({
      category: t[0],
      value: t[3] * SCALE_FACTOR,
    }));

    const prevGrandTotals = salesTrend.map(t => ({
      category: t[0],
      value: t[4],
    }));

    return (
      <div>
        <Group style={{ display: 'block' }} options={this.options} defaultValue={this.defaultValue} onChange={this.onCheckboxChange} />

        <ResponsiveContainer minHeight={400}>
          <LineChart margin={{ top: 20, right: 30, left: 20, bottom: 60 }}>
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis dataKey="category" type="category" allowDuplicatedCategory={false} angle={45} textAnchor="start" tick={{ dy: 10, style: {fontSize: '10px'}}} />
            <YAxis dataKey="value" />

            <Tooltip
              formatter={(value, name) => {
              if (name.includes("Order Count")) {
                return value / SCALE_FACTOR;
              }
              else if (name.includes("Grand Total")) {
                return value.toLocaleString();
              }

              return value;
            }}
            />

            <Legend verticalAlign="top" />

            <Line dataKey="value" data={grandTotals} name="Grand Total" key="Grand Total" stroke="#cb0000" />
            {this.renderLine(orderCounts, "Order Count", { strokeDasharray: "3 4 5 2" })}
            {this.renderLine(prevGrandTotals, "Last Year Grand Total", { stroke: "#ff8000" })}
            {this.renderLine(prevOrderCounts, "Last Year Order Count", { stroke: "#00A309", strokeDasharray: "3 4 5 2" })}

          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default SalesTrend;
