import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation AddBrandMutation($input: AddBrandInput!) {
    addBrand(input:$input) {
      brandEdge {
        cursor
        node {
          id
          name
          imageUrl
          urlSlug
          status
          metaTitle
          metaDescription
          description
          description2
          expressShipping
          privateLabel
          searchScoreMultiplier
          popular
          popularScore
        }
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
