import React from 'react';
import { get, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { Divider, Switch, Tabs } from 'antd';
import { DatePicker } from '~/components/form';
import BySubcategoryReport from './BySubcategoryReport';
import { TIMEZONE } from './helper';
import SalesTrend from './SalesTrend';
import SearchCustomer from './SearchCustomer';
import SearchProduct from './SearchProduct';

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

class BySubcategoryMgrReport extends BySubcategoryReport {
  constructor(props) {
    super(props);

    const { match, viewer } = props;

    const initVariables = match.route.prepareVariables(match.params, match);
    const { filterBy } = initVariables;
    const { filter } = filterBy.find(item => item.field === 'branches') || {};
    this.branchCodeMgr = (filter || "").split(",").filter(i => i);
    this.stores = get(viewer, 'stores.edges', []).filter(({ node }) => this.branchCodeMgr.includes(node.branchCode));
    const mgrBranches = this.stores.map(store => ({
      code: store.node.branchCode,
      name: store.node.name
    }));

    this.state = {
      ...this.state,
      mgrLocations: [],
      _branches: mgrBranches,
    };
  }

  /**
   * When user load this page, `routes_App_Query` and `routes_BySubcategoryReport_Query` will make an request, one after another to the server.
   * `prepareVariables` for this current route will complete first, before `setRoles` in App.js gets to update the roles in the memory.
   * Also because `prepareVariables` get call multiple times, after `setRoles` completed,
   * it will have the correct `initVariables` in the memory, but not the correct fetched data.
   * This happens when the manager's store(s) are modified.
   *
   * Hence it's necessary to call `refetch` to fetch the correct data.
   */
  componentDidMount() {
    this.refetch();
  }

  onMrgLocationChange = (locations) => {
    if (isEmpty(locations)) {
      this.setState({mgrLocations: [], locations: this.branchCodeMgr}, () => {this.refetch()});
    } else {
      this.setState({mgrLocations: locations});
      this.onLocationChange(locations);
    }
  }

  render() {
    const { viewer } = this.props;
    const barChartReport = get(viewer, 'barChartReport.barchart', []);
    const general = get(viewer, 'barChartReport.general', []);
    const bySubcategory = get(viewer, 'barChartReport.overall_by_subcategory', []);
    const byCategory = get(viewer, 'barChartReport.overall_by_category', []);
    const brandTrend = get(viewer, 'barChartReport.brand_trend', []);
    const supplierTrend = get(viewer, 'barChartReport.overall_by_suppliers', []);
    const revenues = get(viewer, 'barChartReport.revenues', 0);
    const suppliers = this.getSuppliers(viewer);
    const { brandId, byDateGpCreated, byBrand, byUnlistedProduct, custCode, dateRange, dataSource, dateGpCreated, dt, dtShow, loading, operators, orderBy, subcategoryId, categoryId, activeKey, query, tagNos } = this.state;
    const { _branches, mgrLocations } = this.state;
    const { clickHouseData, showUnlistedProduct } = this.getConditions(this.state);

    return (
      <div>
        {this.renderHeader()}

        <RangePicker
          key={dateRange}
          value={dateRange}
          onChange={this.onDateChange}
          disabledDate={(current) => current && current < moment().startOf('day').subtract(90, 'days')}
          timezone={TIMEZONE}
        />

        {this.renderDropdown(this.inputs.dataSource, dataSource, { disabled: true })}

        {this.renderDropdown(this.inputs.category, categoryId)}

        {this.renderDropdown(this.inputs.subcategory, subcategoryId)}

        {this.renderDropdown(this.inputs.brand, brandId)}

        {this.renderGlobalFilter("Supplier", this.state.suppliers, this.onSupplierChange, get(viewer, "suppliers", []))}

        {this.renderGlobalFilter("Opeator", operators, this.onOperChange, get(viewer, "staff", []))}

        {this.renderGlobalFilter("Store", mgrLocations, this.onMrgLocationChange, _branches)}

        {clickHouseData && <SearchCustomer viewer={this.props.viewer} searchQuery={query} setSearchText={this.setSearchText} />}
        {clickHouseData && <SearchProduct viewer={this.props.viewer} tagNos={tagNos} setSearchText={this.setSearchProductText} />}

        {this.renderSortBy(orderBy)}

        <div style={{marginTop: '5px'}}>
          Date GP Created: <Switch style={{"marginRight": "10px"}} defaultChecked={byDateGpCreated} checked={byDateGpCreated} onChange={this.onByDateGPCreatedChange}  />

          {byDateGpCreated && <RangePicker key={dateGpCreated} value={dateGpCreated} onChange={this.onDateGPcreatedChange} />}
        </div>

        {!this.isImportProducts() && (
        <div style={{marginTop: '5px'}}>
          By Brand: <Switch defaultChecked={byBrand} checked={byBrand} onChange={this.onByBrandChange}  />

          {byBrand && this.renderBrandFilter()}
        </div>
)}

        {showUnlistedProduct && (
          <div style={{ marginTop: '5px' }}>
            By Unlisted Product: <Switch defaultValue={byUnlistedProduct} checked={byUnlistedProduct} onChange={this.onByUnlistedProductChange} />
          </div>
        )}
        <Divider />

        {this.renderLoading(loading)}

        {clickHouseData ? this.renderTotal(barChartReport) : this.renderOnlineTotal(barChartReport)}

        {clickHouseData && (
          <Tabs activeKey={activeKey} onChange={this.onActiveKeyChange}>
            <TabPane tab="General" key="General">
              {this.renderDownloadBtn()}
              {this.renderShowAllBtn(general)}
              {general && this.renderBarChart(general, "model", orderBy, revenues)}
            </TabPane>

            <TabPane tab="By Subcategory" key="By Subcategory">
              {this.renderTrendTabs(bySubcategory, subcategoryId)}
            </TabPane>

            <TabPane tab="By Category" key="By Category">
              {this.renderTrendTabs(byCategory, categoryId)}
            </TabPane>

            <TabPane tab="By Brand" key="By Brand">
              {this.renderTrendTabs(brandTrend, brandId)}
            </TabPane>

            <TabPane tab="By Supplier" key="By Supplier">
              {this.renderDownloadBtn()}
              {!loading && this.renderTableBySupplier(supplierTrend, suppliers)}
            </TabPane>

            <TabPane tab="By Operator" key="By Operator">
              {!loading && activeKey === "By Operator" && this.renderTablebyEntity(barChartReport)}
            </TabPane>

            <TabPane tab="By Store" key="By Store">
              {!loading && activeKey === "By Store" && this.renderTablebyEntity(barChartReport)}
            </TabPane>

            <TabPane tab="By Customer" key="By Customer">
              {!custCode && activeKey === "By Customer" && !loading && this.renderTableByCust(barChartReport)}
              {custCode && activeKey === "By Customer" && !loading && this.renderCustTabs()}
            </TabPane>

            <TabPane tab="Sales Trend" key="Sales Trend">
              {dtShow && <div>By Hour: <Switch defaultChecked={dt} checked={dt === 'hour'} onChange={this.onDtChange}  /></div>}
              <SalesTrend SalesTrend={barChartReport} />
            </TabPane>
          </Tabs>
        )}
      </div>
    )
  }
};

export default BySubcategoryMgrReport;
