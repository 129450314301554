/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CategoryHistory_viewer$ref = any;
type DisplayCategory_viewer$ref = any;
type FeaturedBrand_viewer$ref = any;
type SelectProduct_viewer$ref = any;
type UrlRefresher_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CategoryForm_viewer$ref: FragmentReference;
declare export opaque type CategoryForm_viewer$fragmentType: CategoryForm_viewer$ref;
export type CategoryForm_viewer = {|
  +id: string,
  +$fragmentRefs: CategoryHistory_viewer$ref & DisplayCategory_viewer$ref & FeaturedBrand_viewer$ref & SelectProduct_viewer$ref & UrlRefresher_viewer$ref,
  +$refType: CategoryForm_viewer$ref,
|};
export type CategoryForm_viewer$data = CategoryForm_viewer;
export type CategoryForm_viewer$key = {
  +$data?: CategoryForm_viewer$data,
  +$fragmentRefs: CategoryForm_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CategoryForm_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CategoryHistory_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DisplayCategory_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeaturedBrand_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SelectProduct_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UrlRefresher_viewer"
    }
  ],
  "type": "Admin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'fb7ce634b83c98f82660dcf642a938a5';

module.exports = node;
