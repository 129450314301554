/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddCategoryInput = {|
  aliases?: ?$ReadOnlyArray<?string>,
  description?: ?string,
  description2?: ?string,
  displayCategories?: ?$ReadOnlyArray<?InputDisplayCategory>,
  displayMode?: ?string,
  enableFromTo?: ?$ReadOnlyArray<?string>,
  featured?: ?boolean,
  includeInFilter?: ?boolean,
  includeInMenu: boolean,
  includeInSearch: boolean,
  includeInSitemap?: ?boolean,
  internalPriceMatch?: ?boolean,
  jsonLd?: ?string,
  metaDescription?: ?string,
  metaTitle?: ?string,
  name: string,
  position?: ?number,
  previewDescription?: ?string,
  scheduleAssignments?: ?$ReadOnlyArray<?InputCategorySchedule>,
  scheduleRemovals?: ?$ReadOnlyArray<?InputCategorySchedule>,
  status: boolean,
  wideImage?: ?boolean,
|};
export type InputDisplayCategory = {|
  categoryId?: ?string,
  id?: ?string,
  image?: ?any,
  nestedCategoryId?: ?string,
  position?: ?number,
|};
export type InputCategorySchedule = {|
  datetime?: ?string,
  products?: ?$ReadOnlyArray<?InputCategoryScheduleProduct>,
|};
export type InputCategoryScheduleProduct = {|
  productId?: ?string
|};
export type AddCategoryMutationVariables = {|
  input: AddCategoryInput
|};
export type AddCategoryMutationResponse = {|
  +addCategory: ?{|
    +categoryEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +id: string,
        +name: ?string,
        +urlSlug: ?string,
        +description: ?string,
        +description2: ?string,
        +includeInMenu: ?boolean,
        +includeInSearch: ?boolean,
        +includeInSitemap: ?boolean,
        +includeInFilter: ?boolean,
        +internalPriceMatch: ?boolean,
        +featured: ?boolean,
        +position: ?number,
        +aliases: ?$ReadOnlyArray<?string>,
        +displayMode: ?string,
        +brands: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +imageUrl: ?string,
            |}
          |}>
        |},
        +scheduleAssignments: ?$ReadOnlyArray<?{|
          +id: string,
          +datetime: ?string,
          +products: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +name: ?string,
                +mainImage: ?{|
                  +id: string,
                  +url: ?string,
                |},
              |}
            |}>
          |},
        |}>,
        +scheduleRemovals: ?$ReadOnlyArray<?{|
          +id: string,
          +datetime: ?string,
          +products: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +name: ?string,
                +mainImage: ?{|
                  +id: string,
                  +url: ?string,
                |},
              |}
            |}>
          |},
        |}>,
        +status: ?boolean,
        +enableStartDate: ?string,
        +enableEndDate: ?string,
        +previewDescription: ?string,
        +metaTitle: ?string,
        +metaDescription: ?string,
        +wideImage: ?boolean,
        +jsonLd: ?string,
      |},
    |}
  |}
|};
export type AddCategoryMutation = {|
  variables: AddCategoryMutationVariables,
  response: AddCategoryMutationResponse,
|};
*/


/*
mutation AddCategoryMutation(
  $input: AddCategoryInput!
) {
  addCategory(input: $input) {
    categoryEdge {
      cursor
      node {
        id
        name
        urlSlug
        description
        description2
        includeInMenu
        includeInSearch
        includeInSitemap
        includeInFilter
        internalPriceMatch
        featured
        position
        aliases
        displayMode
        brands(first: 9999) {
          edges {
            node {
              id
              name
              imageUrl
            }
          }
        }
        scheduleAssignments {
          id
          datetime
          products(first: 9999) {
            edges {
              node {
                id
                name
                mainImage {
                  id
                  url
                }
              }
            }
          }
        }
        scheduleRemovals {
          id
          datetime
          products(first: 9999) {
            edges {
              node {
                id
                name
                mainImage {
                  id
                  url
                }
              }
            }
          }
        }
        status
        enableStartDate
        enableEndDate
        previewDescription
        metaTitle
        metaDescription
        wideImage
        jsonLd
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 9999
  }
],
v4 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "datetime",
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v3/*: any*/),
    "concreteType": "ProductConnection",
    "kind": "LinkedField",
    "name": "products",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductImage",
                "kind": "LinkedField",
                "name": "mainImage",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "products(first:9999)"
  }
],
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddCategoryPayload",
    "kind": "LinkedField",
    "name": "addCategory",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CategoryEdge",
        "kind": "LinkedField",
        "name": "categoryEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Category",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urlSlug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description2",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "includeInMenu",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "includeInSearch",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "includeInSitemap",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "includeInFilter",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "internalPriceMatch",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "featured",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "position",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "aliases",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayMode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "BrandConnection",
                "kind": "LinkedField",
                "name": "brands",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BrandEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Brand",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "imageUrl",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "brands(first:9999)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CategoryScheduleAssignments",
                "kind": "LinkedField",
                "name": "scheduleAssignments",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CategoryScheduleRemovals",
                "kind": "LinkedField",
                "name": "scheduleRemovals",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enableStartDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enableEndDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "previewDescription",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metaTitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metaDescription",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "wideImage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jsonLd",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddCategoryMutation",
    "selections": (v5/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddCategoryMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "2d3d10c2aa5cca3666691f30986a7934",
    "id": null,
    "metadata": {},
    "name": "AddCategoryMutation",
    "operationKind": "mutation",
    "text": "mutation AddCategoryMutation(\n  $input: AddCategoryInput!\n) {\n  addCategory(input: $input) {\n    categoryEdge {\n      cursor\n      node {\n        id\n        name\n        urlSlug\n        description\n        description2\n        includeInMenu\n        includeInSearch\n        includeInSitemap\n        includeInFilter\n        internalPriceMatch\n        featured\n        position\n        aliases\n        displayMode\n        brands(first: 9999) {\n          edges {\n            node {\n              id\n              name\n              imageUrl\n            }\n          }\n        }\n        scheduleAssignments {\n          id\n          datetime\n          products(first: 9999) {\n            edges {\n              node {\n                id\n                name\n                mainImage {\n                  id\n                  url\n                }\n              }\n            }\n          }\n        }\n        scheduleRemovals {\n          id\n          datetime\n          products(first: 9999) {\n            edges {\n              node {\n                id\n                name\n                mainImage {\n                  id\n                  url\n                }\n              }\n            }\n          }\n        }\n        status\n        enableStartDate\n        enableEndDate\n        previewDescription\n        metaTitle\n        metaDescription\n        wideImage\n        jsonLd\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4d7ca12088034448edc058fe35932317';

module.exports = node;
