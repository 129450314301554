import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';

const CategoryWarnModal = (props) => {
    const { data, form, idType, attrs, refetch } = props;
    const open = data.length > 0;
    const [visible, setVisible] = useState(open);

    useEffect(() => {
      setVisible(data.length > 0);
    }, [data]);

    const handleCancel = () => {
      setVisible(false);

      const ids = form.getFieldValue(idType);
      if (ids && ids.length > 0) {
        const newIds = ids.slice(0, -1);
        form.setFieldValue(idType, newIds);

        const subcategoryIds = form.getFieldValue('subcategoryIds') || [];
        const attributes = attrs()
        refetch(subcategoryIds, [], attributes, []);
      }
    };

    const handleOk = () => {
      setVisible(false);
    };

    return(
      <Modal
        visible={visible}
        closable={false}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="ok" type="primary" onClick={handleOk}>
            Proceed
          </Button>,
        ]}
      >
        The category assignment for this product is likely to be an ERROR. Please double check the category assignment with the product manager before you proceed.
        You are assigning:
        <ul>
          {data.length > 0 && data.map((item) => (
            <li key={item.category+item.subcategory}> <b>{item.subcategory}</b> under <b>{item.category}</b> </li>
          ))}
        </ul>
      </Modal>
    )
  }

  CategoryWarnModal.propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        category_id: PropTypes.string.isRequired,
        subcategory_id: PropTypes.string.isRequired,
      })
    ).isRequired,
    form: PropTypes.shape({
      getFieldValue: PropTypes.func.isRequired,
      setFieldValue: PropTypes.func.isRequired
    }).isRequired,
    idType: PropTypes.string.isRequired,
    attrs: PropTypes.func.isRequired,
    refetch: PropTypes.func.isRequired
  };

  export default CategoryWarnModal;
