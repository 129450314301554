import { graphql } from 'react-relay';
import { buildMutation } from '../../../mutation';

const mutation = graphql`
  mutation UpdateCategoryMutation($input: UpdateCategoryInput!) {
    updateCategory(input:$input) {
      category {
        name
        urlSlug
        description
        description2
        includeInMenu
        includeInSearch
        includeInSitemap
        includeInFilter
        internalPriceMatch
        featured
        position
        aliases
        displayMode
        displayCategories {
          id
          categoryId
          nestedCategoryId
          position
          imageUrl
        }
        brands(first: 9999) {
          edges {
            node {
              id
              name
              imageUrl
            }
          }
        }
        featuredBrands(first:9999) {
          edges {
            node {
              brand {
                id
                name
                imageUrl
              }
              subcategory {
                id
                name
              }
            }
          }
        }
        scheduleAssignments {
          id
          datetime
          products(first: 9999) {
            edges {
              node {
                id
                name
                mainImage {
                  id
                  url
                }
              }
            }
          }
        }
        scheduleRemovals {
          id
          datetime
          products(first: 9999) {
            edges {
              node {
                id
                name
                mainImage {
                  id
                  url
                }
              }
            }
          }
        }
        status
        enableStartDate
        enableEndDate
        previewDescription
        metaTitle
        metaDescription
        wideImage
        jsonLd
        ...CategoryHistory_category
        ...WebsiteRef_category
      }
    }
  }
`;

const commit = buildMutation(mutation, {
});

export default { commit };
